<template>
  <v-card
    flat
    class="pa-3 mt-2"
  >
    <v-card-text class="d-flex">
      <v-avatar
        rounded
        size="120"
        class="me-6"
      >
        <v-img :src="get_image"></v-img>
      </v-avatar>

      <!-- upload photo -->
      <div>
        <v-file-input
          show-size
          v-model="user_pic"
          ref="user_pic"
          truncate-length="18"
          style="cursor: pointer"
          label="Choose Your User Picture"
          accept=".jpeg,.png,.jpg,GIF"
        ></v-file-input>

        <p class="text-sm mt-5">
          {{ inputFileName }}
        </p>
      </div>
    </v-card-text>

    <v-card-text>
      <v-form class="multi-col-validation mt-6">
        <v-row>
          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="fullnames"
              ref="fullnames"
              label="Full Names"
              dense
              outlined
            ></v-text-field>
          </v-col>

          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="username"
              ref="username"
              label="Username"
              dense
              outlined
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="phone_number"
              ref="phone_number"
              label="Phone Number (+237)"
              dense
              outlined
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="email"
              ref="email"
              dense
              label="Email"
              outlined
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <spinner v-if="loading"></spinner>
            <v-btn v-else
              color="primary"
              class="me-3 mt-4"
              @click="updateAccountDetails()"
            >
              Save changes
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import Spinner from "@/components/Spinner.vue";
import axios from 'axios'
import { mdiAlertOutline, mdiCloudUploadOutline } from '@mdi/js'

export default {
  components: {
    Spinner,
  },
  
  data() {
    const status = ['Active', 'Inactive', 'Pending', 'Closed']

    return {
      status,
      loading: false,
      inputFileName: "Allowed JPG or PNG. Max size of 5Mbytes",
      user_pic: [],
      username: "",
      fullnames: "",
      phone_number: "",
      email: "",
      get_image: "",
      icons: {
        mdiAlertOutline,
        mdiCloudUploadOutline,
      },
    }
  },

  methods:{
      async updateAccountDetails(){
        console.log(this.$store.state.user_info)
          if(this.username.length < 4){
              this.$store.commit('setSnackBarMessage', "User name is too short, user name needs to be at least 4 characters")
              this.$store.commit('setSnackBarColor', "red darken-4")
              this.$store.commit('activateSnackBar', true)
              this.$refs.username.focus()  // this causes a an auto fucos to the element
          }
          else if(this.fullnames.length === ""){
              this.$store.commit('setSnackBarMessage', "Please input full names")
              this.$store.commit('setSnackBarColor', "red darken-4")
              this.$store.commit('activateSnackBar', true)
              this.$refs.fullnames.focus()  // this causes a an auto fucos to the element
          }
          else if(this.phone_number.length === ""){
              this.$store.commit('setSnackBarMessage', "Please input phone number")
              this.$store.commit('setSnackBarColor', "red darken-4")
              this.$store.commit('activateSnackBar', true)
              this.$refs.phone_number.focus()  // this causes a an auto fucos to the element
          }
          else if(this.email.length === ""){
              this.$store.commit('setSnackBarMessage', "Please input email")
              this.$store.commit('setSnackBarColor', "red darken-4")
              this.$store.commit('activateSnackBar', true)
              this.$refs.email.focus()  // this causes a an auto fucos to the element
          }
          else if(this.user_pic.size > 6000000){
              this.$store.commit('setSnackBarMessage', "Please upload a picture less than 5mb")
              this.$store.commit('setSnackBarColor', "red darken-4")
              this.$store.commit('activateSnackBar', true)
              this.$refs.user_pic.focus()  // this causes a an auto fucos to the element
          }
          else {
              this.loading = true
              console.log(this.user_pic)

              let formData = new FormData()
              formData.append('username', this.username)
              formData.append('fullnames', this.fullnames)
              formData.append('email', this.email)
              formData.append('user_pic', this.user_pic)

              await axios
                  .post('/api/v1/topup/update_account_details/account/', formData, {headers: {'Content-Type': 'multipart/form-data'}})
                  .then(response => {
                      this.$store.commit('setSnackBarMessage', response.data[0].text)
                      this.$store.commit('setSnackBarColor', response.data[0].color)
                      this.$store.commit('activateSnackBar', true)
                      this.$store.commit('setSnackBarTimeOut', 8000)  // set time out for snack bar to 6seconds and remember too reset after 

                      if (response.data[0].category === "success"){
                        this.$router.go()
                      }
                  })
                  .catch(error => {
                      if (error.response){
                          for (const property in error.response.data){
                              this.$store.commit('setSnackBarMessage', error.response.data[property][0])
                              this.$store.commit('setSnackBarColor', "red darken-4")
                              this.$store.commit('activateSnackBar', true)
                          }
                      }else{
                          this.$store.commit('setSnackBarMessage', "An error occured while logging into your account")
                          this.$store.commit('setSnackBarColor', "red darken-4")
                          this.$store.commit('activateSnackBar', true)
                      }
                  })
  
              this.loading = false
          }

      },

      async updateAccountPic(){
          if(this.username.length < 4){
              this.$store.commit('setSnackBarMessage', "User name is too short, user name needs to be at least 4 characters")
              this.$store.commit('setSnackBarColor', "red darken-4")
              this.$store.commit('activateSnackBar', true)
              this.$refs.username.focus()  // this causes a an auto fucos to the element
          }
          else {
              this.loading = true
              const formData = {
                username: this.username,
                fullnames: this.fullnames,
                phone_number: this.phone_number,
                email: this.email,
              }

              await axios
                  .post('/api/v1/topup/update_account_details/info/', formData)
                  .then(response => {
                      this.$store.commit('setSnackBarMessage', response.data[0].text)
                      this.$store.commit('setSnackBarColor', response.data[0].color)
                      this.$store.commit('activateSnackBar', true)
                      this.$store.commit('setSnackBarTimeOut', 8000)  // set time out for snack bar to 6seconds and remember too reset after 

                      if (response.data[0].category === "success"){
                        this.$router.go()
                      }
                  })
                  .catch(error => {
                      if (error.response){
                          for (const property in error.response.data){
                              this.$store.commit('setSnackBarMessage', error.response.data[property][0])
                              this.$store.commit('setSnackBarColor', "red darken-4")
                              this.$store.commit('activateSnackBar', true)
                          }
                      }else{
                          this.$store.commit('setSnackBarMessage', "An error occured while logging into your account")
                          this.$store.commit('setSnackBarColor', "red darken-4")
                          this.$store.commit('activateSnackBar', true)
                      }
                  })
  
              this.loading = false
          }

      },

      getUserInfo() {
        if (this.$store.state.isAuthenticated && !this.$store.state.user_info_state){
            axios
                .get('/api/v1/home/get/user/info/')
                .then(response => {
                  console.log("this.$store.state.user")
                  console.log(response.data)
                  if (this.$store.state.username == ''){
                    this.$store.state.username = response.data[0]["user"]['username'] // get the data and fill into username
                  } 
                  this.$store.state.user = response.data[0]["user"]  // get the data and fill into username
                  this.$store.state.user_info_state = response.data[0]["user_info"]  // get the data and fill into username
                  this.$store.state.user_info = response.data[0]  // get the data and fill into username

                  this.username = this.$store.state.user_info["user"]["username"]
                  this.fullnames = this.$store.state.user_info["user"]["first_name"]
                  this.phone_number = this.$store.state.user_info["user_profile"]["phone_number"]
                  this.email = this.$store.state.user_info["user"]["email"]
                  this.get_image = this.$store.state.user_info["user_profile"]["get_image"]
                  console.log(this.get_image)
                  if (!this.$store.state.user_info["user_profile"]["top_up"]){
                    logOut()
                    this.$store.commit('setSnackBarMessage', "Sorry your account doesn't have the required priviledges to access this portal")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                  }
                })
                .catch(error => {
                    this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                })
        }
      },
  },

  mounted(){
    this.getUserInfo()
  },
}
</script>
