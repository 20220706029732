<template>
  <v-card
    flat
    class="pa-3 mt-2"
  >
    <v-form class="multi-col-validation">
      <v-card-text class="pt-5">
        <v-row>
          <v-col cols="12">
            <v-textarea
              v-model="description"
              outlined
              rows="3"
              ref="description" id="description"
              label="A brief description of your life so far"
            ></v-textarea>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="birthday"
              ref="birthday"
              type="date"
              outlined
              dense
              label="Birthday"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="profession"
              ref="profession"
              outlined
              dense
              label="Your Profession"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="residence"
              outlined
              dense
              id="residence" ref="residence"
              label="Residential Address"
              :rules="[v => !!v || 'Your Residential Address is required']"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-select
              v-model="gender"
              ref="gender"
              outlined
              dense
              label="Gender"
              :items="genderItems"
            ></v-select>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-select
              v-model="language"
              ref="language"
              outlined
              dense
              label="Language"
              :items="['English','Spanish','French','German']"
            ></v-select>
          </v-col>

        </v-row>
      </v-card-text>

      <v-card-text>
        <spinner v-if="loading"></spinner>
        <v-btn v-else
            color="primary"
            class="me-3 mt-3"
            @click="updateAccountDetails()"
          >
          Save changes
        </v-btn>
      </v-card-text>
    </v-form>
  </v-card>
</template>

<script>
import Spinner from "@/components/Spinner.vue";
import axios from 'axios'

export default {
  components: {
    Spinner,
  },

  data() {
    return {
      loading: false,
      genderItems: [
        'Male',
        'Female',
      ],
      description: "",
      file: "",
      gender: "",
      language: "",
      profession: "",
      birthday: "",
      residence: "",    
    }
  },

  methods:{
      setAccountDetails(){
          this.gender = this.$store.state.user_info["user_profile"]["gender"]
          this.birthday = this.$store.state.user_info["user_profile"]["date_of_birth"]
          this.language = this.$store.state.user_info["user_profile"]["language"]
          this.profession = this.$store.state.user_info["user_profile"]["profession"]
          this.residence = this.$store.state.user_info["user_profile"]["address"]
          this.description = this.$store.state.user_info["user_worker_info"]["personal_info"]
      },

      async updateAccountDetails(){
          console.log('updateAccountDetails')
          if(this.gender === ""){
              this.$store.commit('setSnackBarMessage', "Please select gender.")
              this.$store.commit('setSnackBarColor', "red darken-4")
              this.$store.commit('activateSnackBar', true)
              this.$refs.gender.focus()  // this causes a an auto fucos to the element
          }
          else if(this.residence === ""){
              this.$store.commit('setSnackBarMessage', "Please input your residence.")
              this.$store.commit('setSnackBarColor', "red darken-4")
              this.$store.commit('activateSnackBar', true)
              this.$refs.residence.focus()  // this causes a an auto fucos to the element
          }
          else if(this.profession === ""){
              this.$store.commit('setSnackBarMessage', "Please input your profession.")
              this.$store.commit('setSnackBarColor', "red darken-4")
              this.$store.commit('activateSnackBar', true)
              this.$refs.profession.focus()  // this causes a an auto fucos to the element
          }
          else if(this.birthday === ""){
              this.$store.commit('setSnackBarMessage', "Please input your birthday.")
              this.$store.commit('setSnackBarColor', "red darken-4")
              this.$store.commit('activateSnackBar', true)
              this.$refs.birthday.focus()  // this causes a an auto fucos to the element
          }
          else if(this.language === ""){
              this.$store.commit('setSnackBarMessage', "Please select a language")
              this.$store.commit('setSnackBarColor', "red darken-4")
              this.$store.commit('activateSnackBar', true)
              this.$refs.language.focus()  // this causes a an auto fucos to the element
          }
          else if(this.description === ""){
              this.$store.commit('setSnackBarMessage', "Please input a brief description of who you are.")
              this.$store.commit('setSnackBarColor', "red darken-4")
              this.$store.commit('activateSnackBar', true)
              this.$refs.description.focus()  // this causes a an auto fucos to the element
          }
          else {
              this.loading = true
              const formData = {
                gender: this.gender,
                residence: this.residence,
                profession: this.profession,
                birthday: this.birthday,
                language: this.language,
                description: this.description,
              }

              await axios
                  .post('/api/v1/topup/update_account_details/info/', formData)
                  .then(response => {
                    this.$store.commit('setSnackBarMessage', response.data[0].text)
                    this.$store.commit('setSnackBarColor', response.data[0].color)
                    this.$store.commit('activateSnackBar', true)
                    this.$store.commit('setSnackBarTimeOut', 8000)  // set time out for snack bar to 6seconds and remember too reset after 

                    if (response.data[0].category === "success"){
                      this.$router.go()
                    }
                  })
                  .catch(error => {
                      if (error.response){
                          for (const property in error.response.data){
                              this.$store.commit('setSnackBarMessage', error.response.data[property][0])
                              this.$store.commit('setSnackBarColor', "red darken-4")
                              this.$store.commit('activateSnackBar', true)
                          }
                      }else{
                          this.$store.commit('setSnackBarMessage', "An error occured while logging into your account")
                          this.$store.commit('setSnackBarColor', "red darken-4")
                          this.$store.commit('activateSnackBar', true)
                      }
                  })
              this.loading = false
          }

      },
  },

  mounted(){
      this.setAccountDetails() 
  },

}
</script>
